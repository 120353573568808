namespace TotalContest {
    import Contest = TotalContest.Contest;

    export class reCaptchaBehaviour {
        readonly invisible: Boolean;
        readonly widget;
        private $form;
        private $recaptcha;
        private valid: Boolean = false;

        constructor(private contest: Contest) {
            this.$recaptcha = contest.element.find('.g-recaptcha');
            this.$form = contest.element.find('form');
            this.invisible = this.$recaptcha.data('size') === 'invisible';
            this.widget = this.invisible ? this.$form.find('[type="submit"]').get(0) : this.$recaptcha.get(0);
            if (!window['grecaptcha']) {
                jQuery.getScript('https://www.google.com/recaptcha/api.js', this.initAndBind.bind(this));
            } else {
                this.initAndBind();
            }
        }

        initAndBind() {
            // Invisible
            if (this.invisible) {
                this.$form.on('submit', (event) => this.validate(event));
                window['grecaptcha'].ready(() => this.render());
            } else {
                window['grecaptcha'].ready(() => this.render());
            }
        }

        destroy() {
            this.$recaptcha.remove();
        }

        render() {
            window['grecaptcha'].render(this.widget, {
                    sitekey: this.$recaptcha.data('sitekey'),
                    callback: () => {
                        this.valid = true;
                        if (this.invisible) {
                            this.$form.submit();
                        }
                    }
                }
            );
        }

        validate(event) {
            if (!this.valid) {
                event.preventDefault();
            }
        }
    }
}
