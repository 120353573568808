///<reference path="../../../../build/typings/index.d.ts" />
///<reference path="validations.ts" />
///<reference path="behaviours/embed.ts" />
///<reference path="behaviours/ajax.ts" />
///<reference path="behaviours/submission-form.ts" />
///<reference path="behaviours/vote-form.ts" />
///<reference path="behaviours/submissions-listing.ts" />
///<reference path="behaviours/scroll-overflow.ts" />
///<reference path="behaviours/modal.ts" />
///<reference path="behaviours/recaptcha.ts" />


namespace TotalContest {
    export var Contests = {};

    export class Contest {
        public behaviours: any = {};
        public config: any = {};
        public id;
        public submissionId;
        public screen;

        constructor(public element, public viaAjax = false, private viaAsync = false, public uid = (Date.now() + (Math.random() * 10000000).toFixed(0))) {
            this.id = element.attr('totalcontest');
            this.submissionId = element.attr('totalcontest-submission-id');
            this.config = JSON.parse(element.find('[totalcontest-config]').text());
            this.screen = element.attr('totalcontest-screen');

            if (TotalContest.Contests[this.uid] && viaAjax) {
                // Destroy the old instance
                TotalContest.Contests[this.uid].destroy();
                element.fadeIn();
            }

            // Save instance for future usage
            TotalContest.Contests[this.uid] = this;
            element.data('contest', TotalContest.Contests[this.uid]);

            if (this.screen === 'contest.participate') {
                // Submission form
                this.behaviours['submissionForm'] = new SubmissionFormBehaviour(this);
            } else if (this.screen == 'contest.submissions') {
                // Submission listing
                this.behaviours['submissionsListing'] = new SubmissionsListingBehaviour(this);
            } else if (this.screen == 'submission.view') {
                this.behaviours['voteForm'] = new VoteFormBehaviour(this);
            }

            // reCaptcha
            if (element.find('.g-recaptcha').length) {
                this.behaviours['recaptcha'] = new reCaptchaBehaviour(this);
            }

            // Ajax
            if (this.config['behaviours']['async'] || this.config['behaviours']['ajax']) {
                this.behaviours['ajax'] = new AjaxBehaviour(this);

                //@PRO
                if (this.config['behaviours']['modal'] && this.screen == 'contest.submissions') {
                    this.behaviours['modal'] = new ModalBehaviour(this);
                }
                //@END-PRO
            } else {
                element.find('option[value^="http"]').closest('select').on('change', (event) => {
                    let selected = jQuery(event.currentTarget).find('option:selected');
                    let url = selected.attr('value');
                    if (url) {
                        window.location.href = url;
                    }
                });
            }

            // Async
            if (this.config['behaviours']['async']) {
                this.behaviours['ajax'].load(element.attr('totalcontest-ajax-url'));
            }

            // Embed resizing
            if (window.top !== window.self) {
                this.behaviours['embed'] = new EmbedResizingBehaviour(this);
                this.behaviours['embed'].postHeight();
            }

            // Scroll
            // this.behaviours['scrollOverflow'] = new ScrollOverflowBehaviour(this);

            Hooks.doAction('totalcontest/init', this);
        }


        destroy() {
            jQuery.each(this.behaviours, function (id, behaviour) {
                if (behaviour.destroy) {
                    behaviour.destroy();
                }
            });
            this.element.remove();
            Hooks.doAction('totalcontest/destroy', this);
        }

        isViaAjax() {
            return this.viaAjax;
        }


        isViaAsync() {
            return this.viaAsync;
        }

        off(event, callback) {

            this.element.off(event, callback);
        }

        on(event, callback) {

            this.element.on(event, callback);
        }

        trigger(event) {
            this.element.triggerHandler(event);
        }
    }

    export class Hooks {
        static addAction(event, callback) {

            jQuery(TotalContest).on(event, function (event) {
                let payload = [].slice.call(arguments).splice(1);
                return callback.apply(event, payload);
            });
        }

        static doAction(event, payload) {
            jQuery(TotalContest).triggerHandler(event, payload);
        }
    }

    export class Utils {
        public static getFileSizeForHuman(sizeInBytes) {
            return ((sizeInBytes / Math.pow(1024, Math.floor(Math.log(sizeInBytes) / Math.log(1024)))) || 0).toFixed(2) + ' ' + ' KMGTP'.charAt(Math.floor(Math.log(sizeInBytes) / Math.log(1024))) + 'B'
        }

        public static getUrlParameters(url) {
            let params = {};
            window['decodeURIComponent'](url).replace(/[?&]+([^=&]+)=([^&]*)/gi, function (search, key, value) {
                params[key] = value;
            });

            return params;
        };

        public static refreshTinyMCE() {
            if (window['tinymce']) {
                setTimeout(
                    () => {

                        for (let id in window['tinyMCEPreInit'].mceInit) {
                            window['tinymce'].remove();

                            var init = window['tinyMCEPreInit'].mceInit[id];
                            var $wrap = window['tinymce'].$(`#wp-${id}-wrap`);

                            if (($wrap.hasClass('tmce-active') || !window['tinyMCEPreInit'].qtInit.hasOwnProperty(id)) && !init.wp_skip_init) {
                                window['tinymce'].init(init);
                            }
                        }
                    },
                    100
                );
            }
        }
    }

    jQuery(function ($: any) {
        $('[totalcontest]').each(function () {
            new TotalContest.Contest($(this));
        });

        jQuery(window).on('popstate', (event: any) => {
            $('[totalcontest]').triggerHandler('state', [event.originalEvent.state]);
            return false;
        });
    });
}
